<template>
  <div class="table-wrap">
    <div class="table-container">
      <div class="table-toolbar">
        <div class="title">
          <div class="text">
            <span>政策推送</span>
          </div>
        </div>
        <div class="filters">
          <a-button type="primary" @click="subscribe()">政策订阅</a-button>
        </div>
      </div>
      <div class="table">
        <a-table size="middle" :columns="columns" :row-key="record => record.id" :data-source="list"
          :pagination="pagination" :loading="loading" @change="handleChange">
          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex == 'dept_id'">
              <div>
                <span>{{ record.dept_info?.dept_name }}</span>
              </div>
            </template>
            <template v-if="column.dataIndex == 'declare_item'">
              <div>
                <span>{{ record?.article_info.declare_item_info?.name }}</span>
              </div>
            </template>
            <template v-if="column.dataIndex == 'amount'">
              <div>
                <span>{{ record.article_info?.amount }}万元</span>
              </div>
            </template>
            <template v-if="column.dataIndex == 'jzrq'">
              <div>
                <span>{{ record.article_info?.jzrq }}</span>
              </div>
            </template>
            <template v-else-if="column.dataIndex == 'input_time'">
              <div>
                <span>{{ record.article_info?.input_time?.substring(0, 10) }}</span>
              </div>
            </template>
            <template v-else-if="column.dataIndex == 'release_time'">
              <div>
                <span>{{ record.article_info?.release_time?.substring(0, 10) }}</span>
              </div>
            </template>
            <template v-else-if="column.dataIndex == 'num'">
              <div>
                <span class="numBlock" :class="{ blue: record.num - 0 >= 60, gray: record.num - 0 < 60 }">{{ record.num
                }}%</span>
              </div>
            </template>
            <template v-else-if="column.dataIndex == 'title'">
              <div class="data-title" @click="toUrl(record)">
                <a-tooltip placement="topLeft" :title="record.article_info?.title" :color="'geekblue'">
                  <span>{{ record.article_info?.title }}</span>
                </a-tooltip>
              </div>
            </template>
            <template v-else-if="column.dataIndex == 'opt'">
              <div class="operations">
                <a-button class="btn" type="info" size="small" @click="showDetail(record)">查看</a-button>
              </div>
            </template>
          </template>
        </a-table>
      </div>
    </div>
    <a-modal v-model:visible="visible" title="政策订阅"  width="1000px" :footer="null">
      <a-button type="primary" @click="addAccount()">添加账户</a-button>
      <div class="table">
        <a-table :dataSource="subscribeList" :columns="subscribecolumns">
          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex == 'push_wx'">
              <div>
                <a-checkbox v-if="record.push_message" checked="checked" @click="setMessage(record)">短信</a-checkbox>
                <a-checkbox v-else @click="setMessage(record)">短信</a-checkbox>
                <a-checkbox v-if="record.push_wx" checked="checked" @click="setWx(record)">公众号</a-checkbox>
                <a-checkbox v-else @click="setWx(record)">公众号</a-checkbox>
              </div>
            </template>
            <template v-if="column.dataIndex == 'operate'">
                    <div>
                      <a-popconfirm
                        title="确认删除该账号？"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="delPushaccount(record.id)"
                        @cancel="cancel"
                      >
                        <span class="down-button" >删除</span>

                      </a-popconfirm>
                    </div>
                  </template>
          </template>
        </a-table>
      </div>
    </a-modal>
    <EditDetail ref="editDetailRef" />
    <a-modal v-model:visible="visible2" title="添加账号" @ok="handleOk">
        <a-input v-model:value="mobile" placeholder="请输入手机号" />
      </a-modal>
  </div>
</template>
<script>
import service from '@/service/service';
import EditDetail from './EditDetail.vue';
import { mapState } from 'vuex';
import { message } from 'ant-design-vue';

export default {
    data() {
        return {
            list: [],
            subscribeList:[],
            filter: {},
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
            },
            visible:0,
            visible2:0,
            mobile:"",
            loading: false,
            columns: [
                {
                    title: "推送日期",
                    dataIndex: "input_time"
                },
                {
                    title: "发布日期",
                    dataIndex: "release_time"
                },
                {
                    title: "部门",
                    dataIndex: "dept_id",
                },
                {
                    title: "政策名称",
                    dataIndex: "title",
                    width: 400,
                },
                // {
                //     title: "申报项目",
                //     dataIndex: "declare_item",
                // },
                {
                    title: "奖励金额",
                    dataIndex: "amount",
                },
                {
                    title: "申报截止",
                    dataIndex: "jzrq",
                },
                
                {
                    title: "匹配度",
                    dataIndex: "num",
                },
                {
                    title: "匹配详情",
                    dataIndex: "opt"
                }
            ],

            subscribecolumns: [
                {
                    title: "ID",
                    dataIndex: "userid"
                },
                {
                    title: "订阅用户",
                    dataIndex: "phone"
                },
                {
                    title: "接收方式",
                    dataIndex: "push_wx",
                },
                {
                  title: "操作",
                  dataIndex: "operate",
                },
              
            ],

        };
    },
    mounted() {
        this.handleSearch();
        this.getSubaccount();
    },
    computed: {
      ...mapState(['userinfo']),
    },
    methods: {
        showDetail(item) {
            console.log(item);
            this.$refs['editDetailRef']?.onShow(item.id);
        },
        handleChange(e) {
            this.pagination = { ...e };
            this.handleSearch();
        },
        toUrl(item) {
            //window.open(item.article_info?.url);
            this.$router.push('/prev/policyDetail?id='+item.article_id)
        },
        subscribe(){
          this.visible = 1
        },
        async setMessage(item){
          let params = {
                id: item.id,
            };
            const res = await service.setMessage(params);
            if(res.code == 1){
              message.info(res.msg)
              this.getSubaccount()
            }else{
              message.info("设置失败")
            }
            
        },

        addAccount(){
          this.visible2 = 1
        },

        async delPushaccount(e){


          let params = {
            id:e,
          };
          const res = await service.delete_pushaccount(params);
          if (res.code) {
            message.info(res.msg);
            this.getSubaccount();
          }
        },

        async handleOk(){
          let params = {
            phone:this.mobile,
            pid:this.userinfo.userid
          };
          const res = await service.add_pushaccount(params);
          if (res.code) {
            message.info(res.msg);
            this.getSubaccount();
            this.visible2 = 0
          }
        },

        async setWx(item){
          let params = {
              id: item.id,
          };
          const res = await service.setWx(params);
          if(res.code == 1){
              message.info(res.msg)
              this.getSubaccount()
            }else{
              message.info("设置失败")
            }

        },
        async getSubaccount(){
          let params = {
                userid: this.userinfo.userid,
                page: this.pagination.current,
                pageSize:this.pagination.pageSize
            };
          const res = await service.get_pushaccount_list(params);
          this.subscribeList = res.data;
          console.log(this.subscribeList)
        },

        async handleSearch() {
            this.loading = true;
            let params = {
                page: this.pagination.current
            };
            const res = await service.get_zcts_lists(params);
            this.pagination = { ...this.pagination, total: res.total_number };
            // console.log(data);
            this.list = res.data;
            this.loading = false;
        }
    },
    components: { EditDetail }
}
</script>
<style lang="less" scoped>
.table-wrap {
  margin: 24px 0;
  width: 1400px;
}

.table {
  .data-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 400px;
    cursor: pointer;
    transition: all .25s ease;

    &:hover {
      color: #3399FF;
    }
  }

  .numBlock {
    padding: 0 12px;
    height:30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    color: #fff;
    font-size: 16px;
    min-width: 60px;

    &.blue {
      background: #3399FF;
    }

    &.gray {
      background: #787878;
    }
    
  }
  .down-button{cursor: pointer;}
}</style>